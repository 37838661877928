'use client';

import { Box, Icon, Text, VStack } from '@chakra-ui/react';
import { FaTools } from 'react-icons/fa';

export default function MaintenancePage() {
	return (
		<Box
			minH="100vh"
			display="flex"
			alignItems="center"
			justifyContent="center"
			bgGradient="linear(to-r, gray.200, gray.300)"
		>
			<VStack spacing={4} textAlign="center">
				<Icon as={FaTools} boxSize={16} color="gray.600" />
				<Text fontSize="2xl" fontWeight="bold" color="gray.700">
					Sedang dalam Perbaikan
				</Text>
				<Text fontSize="lg" color="gray.600">
					Untuk sementara aplikasi e-RKAM tidak dapat diakses karena sedang
					dalam perbaikan.
				</Text>
				<Text fontSize="lg" color="gray.600" fontWeight="bold">
					Sampai Hari Kamis, 13 Maret 2025.
				</Text>
			</VStack>
		</Box>
	);
}
